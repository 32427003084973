<!-- 签到墙 -->
<template>
  <div>
    <div class="title_css">2023年10月15日 签到墙</div>
    <div class="box_item_css px1610">
      <div v-for="(item, index) in studentList" :key="index" @click="CsignIn(index,item.signIn)">
        <div class="img_css">
          <img :src="item.portrait">
          <div class="qingjia_Css" v-if="item.qing">
            请假
          </div>
        </div>
        <div class="name_css">{{ item.name }}</div>
        <div class="signIn_css" :class="item.signIn ? 'signInA_css' : 'signInB_css'">{{ item.signIn ? '已签到' : '未签到' }}</div>
      </div>
      <i v-for="item in 12" :key="'id_' + item"></i>
      <div class="rollCall_bottom_css">
          <div @click="clickSelect()">签到结束</div>
        </div>
    </div>
    <div style="height:150px;"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      studentList: [
        {
          name: "甜饮铺",
          portrait:"https://img2.baidu.com/it/u=4004986735,735616996&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          signIn: false,
        },
        {
          name: "凤晚歌",
          portrait:
            "https://p1.itc.cn/q_70/images03/20210909/d086afe51a86435caac101d12af53129.jpeg",
          signIn: false,
        },
        {
          name: "竹青磬",
          portrait:
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202006%2F03%2F20200603234414_8AKVK.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1680852335&t=cedcf304b2d90e5772bf0b18049700af",
          signIn: false,
        },
        {
          name: "醉揽月",
          portrait:
            "https://img0.baidu.com/it/u=1383353381,3757202318&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          signIn: false,
        },
        {
          name: "甜饮铺",
          portrait:"https://img2.baidu.com/it/u=4004986735,735616996&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          signIn: false,
        },
        {
          name: "凤晚歌",
          portrait:
            "https://p1.itc.cn/q_70/images03/20210909/d086afe51a86435caac101d12af53129.jpeg",
          signIn: false,
        },
        {
          name: "竹青磬",
          portrait:
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202006%2F03%2F20200603234414_8AKVK.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1680852335&t=cedcf304b2d90e5772bf0b18049700af",
          signIn: false,
        },
        {
          name: "醉揽月",
          portrait:
            "https://img0.baidu.com/it/u=1383353381,3757202318&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          signIn: false,
        },
        {
          name: "甜饮铺",
          portrait:"https://img2.baidu.com/it/u=4004986735,735616996&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          signIn: false,
        },
        {
          name: "凤晚歌",
          portrait:
            "https://p1.itc.cn/q_70/images03/20210909/d086afe51a86435caac101d12af53129.jpeg",
          signIn: false,
        },
        {
          name: "竹青磬",
          portrait:
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202006%2F03%2F20200603234414_8AKVK.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1680852335&t=cedcf304b2d90e5772bf0b18049700af",
          signIn: false,
        },
        {
          name: "醉揽月",
          portrait:
            "https://img0.baidu.com/it/u=1383353381,3757202318&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          signIn: false,
        },
        {
          name: "甜饮铺",
          portrait:"https://img2.baidu.com/it/u=4004986735,735616996&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          signIn: false,
        },
        {
          name: "凤晚歌",
          portrait:
            "https://p1.itc.cn/q_70/images03/20210909/d086afe51a86435caac101d12af53129.jpeg",
          signIn: false,
        },
        {
          name: "竹青磬",
          portrait:
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202006%2F03%2F20200603234414_8AKVK.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1680852335&t=cedcf304b2d90e5772bf0b18049700af",
          signIn: false,
        },
        {
          name: "醉揽月",
          portrait:
            "https://img0.baidu.com/it/u=1383353381,3757202318&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          signIn: false,
        },
        {
          name: "客梦回",
          portrait:
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202010%2F04%2F20201004121426_325cf.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1680852335&t=cb8497ce86149c64812cda996385e12e",
          signIn: false,
        },
        {
          name: "柒墨姬",
          portrait:
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202003%2F15%2F20200315095845_rvsHc.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1680852335&t=2c96e63e4fafff8c2f5f3eb32c0059b8",
         signIn: false,
         qing: true
        },
        {
          name: "苏璃落",
          portrait:
            "https://img0.baidu.com/it/u=1383353381,3757202318&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          signIn: false,
          qing: true
        }
      ],
    };
  },
  methods:{
    // 签到
    CsignIn(index,signIn){
        if(signIn){
            this.$message.warning('请勿重复签到')
            return
        }
        this.studentList[index].signIn = true
        this.$message.error('恭喜' + this.studentList[index].name + '，签到成功')
    }
  }
};
</script>
<style scoped>
.box_item_css {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  margin: 0 auto;
  background-color: #ffffffda;
  border-radius: 25px;
  padding:30px 15px 15px !important;
  min-height: 620px;
}
.box_item_css > i {
  margin: 0 7px;
  width: 115px;
  height: 0px;
}

.box_item_css > div {
  margin: 0 7px 15px 7px;
  width: 115px;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
}
.img_css{
  width: 115px;
    height: 115px;
    border-radius: 1000px;
    overflow: hidden;
    margin-bottom: 2px;
    position: relative;
}
.qingjia_Css{
  position: absolute;
  top: 0;
  width: 115px;
    height: 115px;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.6);
    color: #e25656;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
}
.img_css>img{
    width: 115px;
    height: 115px;
    border-radius: 1000px;
}
.title_css {
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  padding-top: 40px;
  padding-bottom: 20px;
}
.signInA_css{
    color: #7456e2;
}
.signInB_css{
    color: #e25656;
}
.rollCall_bottom_css {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}
.rollCall_bottom_css > div{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 450px;
  background-color: #59a6f6;
  color: #fff;
  font-size: 20px;
  border-radius: 1000px;
  box-shadow: 0px 0px 15px #9ec0f6;
}
</style>